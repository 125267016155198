import { createRouter, createWebHistory,createWebHashHistory, RouteRecordRaw } from "vue-router";

export const dashboardLayout = () => import("@/views/dashboard/index.vue");
export const nextLayout = () => import("@/views/next/index.vue");
export const editLayout = () => import("@/views/edit/index.vue");
export const agreementLayout = () => import("@/views/agreement/index.vue");
export const aboutLayout = () => import("@/views/about/index.vue");
export const myWorks = () => import("@/views/myWorks/index.vue");
export const screenShots = () => import("@/views/screenShots/index.vue");

// 静态路由
export let constantRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "/",
    component: dashboardLayout,
  },
  {
    path: "/dashboard",
    component: dashboardLayout,
    redirect: "/",
    meta: { hidden: true },
  },
  {
    path: "/next",
    component: nextLayout,
    meta: { hidden: true },
  },
  {
    path: "/edit",
    component: editLayout,
    meta: { hidden: true },
  },
  {
    path: "/agreement",
    component: agreementLayout,
    meta: { hidden: true },
  },
  {
    path: "/about",
    component: aboutLayout,
    meta: { hidden: true },
  },
  {
    path: "/myWorks",
    component: myWorks,
    meta: { hidden: true },
  },
  {
    path: "/screenShots",
    component: screenShots,
    meta: { hidden: true },
  },
];
if(import.meta.env.DEV){
  constantRoutes.push({
    path:"/img",
    component:() => import("@/components/imgUploadDemo.vue")
  })
}
/**
 * 创建路由
 */
const router = createRouter({
  history: import.meta.env.DEV ? createWebHashHistory() : createWebHistory(),
  routes: constantRoutes as RouteRecordRaw[],
  // 刷新时，滚动条位置还原
  scrollBehavior: () => ({ left: 0, top: 0 }),
});

/**
 * 重置路由
 */
export function resetRouter() {
  router.replace({ path: "/" });
}

export default router;
