import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/theme/index.css';
import './style.css'
import 'animate.css'
import router from "@/router";
import { setupStore } from "@/store";
// 修改主题颜色
const el = document.documentElement
// 设置 css 变量
el.style.setProperty('--el-color-primary', '#52C443')

const app = createApp(App)
setupStore(app);
app.use(ElementPlus)
app.use(router)
app.mount('#app')
